.personnel-wrapper{
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 30px;
  position: relative;
  background-image: linear-gradient(62deg, #e4f2ff 0%, #fcf8ff 100%); 
}

#personnel-heading{
  font-weight: 400;
  font-size: 2rem;

}

#personnel-heading span{
  font-weight: 700;
  font-size: 2rem;
}

.personnel-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 40px;
  width: 100%;
  margin-top: 40px;
  align-items: center;
  text-align: center;
}



.personnel-logo{
    padding: 0 30px;
    justify-self: center;
    width: 350px;
}

.personnel-logo p {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5;
}

.personnel-logo p:nth-child(3) {
  font-size: 15px;
  font-weight: 400;
  color: rgb(107, 106, 106);
}

.personnel-container img{
  width: 120px;
  height: 120px;
}

/* .personnal-container p{
  font-size: 15px;
  font-weight: 500;
} */

.retired{
  grid-column-start: 1;
  grid-column-end: 3;
}

@media only screen and (max-width : 768px) {
  .personnel-wrapper {
    padding: 20px 0;
  }

  #personnel-heading {
    font-size: 20px;
    font-weight: 500;
    position: relative;
  }

  #personnel-heading span{
    font-weight: 700;
    font-size: 20px;
  }


  .personnel-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    column-gap: 5px;
    row-gap: 40px;
    width: 100%;
  }



  .personnel-logo{
    margin-bottom: 10px;
    width: 300px;
  }

  .personnel-logo{
      padding: 0 30px;
  }

  .personnel-logo p {
      font-size: 17px;
      font-weight: 600;
      line-height: 1.5;
  }

  .personnel-logo p:nth-child(3) {
    font-size: 15px;
    font-weight: 400;
}
  
  .personnel-container img{
    width: 110px;
    height: 110px;
  }

  .retired{
    grid-column-start: 1;
    grid-column-end: 1;
  }
  
}