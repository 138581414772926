.apss-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 80px 30px;
  background-color: #ffffff;
  
}

.app-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  text-align: center;
}

.app-ss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.one , .two {
  width: 30%;
}

.app-info-inner {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.39;
  color: #000000;
}



#aryo-name{
  color: #4285f4;
}

#man-img {
  /* max-width: 350px; */
  max-width: 100%;
}

#medal2 {
  width: 100px;
}

#moneyplant-img {
  /* width: 300px; */
  max-width: 100%;
}

#portable {
  float: left;
  color: gray;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .apss-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 50px 30px;
    gap: 20px;
  }

  .app-info {
    display: flex;
    width: 100%;
  }

  .app-info-inner {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.39;
    color: #000000;
    margin-top: 10px;
  }

  .app-ss {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #man-img {
    /* width: 300px; */
    max-width: 100%;
    margin: 0 auto;
  }

  #moneyplant-img {
    width: 300px;
    margin: 0 auto;
  }

  .app-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4px;
    text-align: center;
  }
}