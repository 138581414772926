.footer-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
}

.footer-container-one {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 50px 30px 30px;
}

.footer-box {
  display: flex;
  justify-content: space-around;
  width: 60%;
}

#aryologo {
  width: 100px;
}

.advertise p {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-top: 8px;
  padding-left: 4px;
}

.footer-badge {
  margin-top: 8px;
  margin-left: -8px;
}

#badge {
  width: 170px;
}

.footer-products {
  width: 50%;
}

.footer_company_info {
  width: 50%;
}

.footer-products p {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.footer-products ul {
  list-style: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.footer-products ul li {
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

.product_link {
  text-decoration: none;
  color: #fff;
}

.footer-company p {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.footer-company ul {
  list-style: none;
  color: white;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-decoration: none;
}

#contact_link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.footer-contact {
  margin-top: 20px;
}

.footer-contact p {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.footer-contact ul {
  list-style: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#call-logo {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}

#mail-logo {
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
}

#address {
  display: flex;
}

#address_logo {
  width: 20px;
  height: 20px;
  font-weight: 600;
}

#call,
#mail {
  text-decoration: none;
}

#social-media {
  display: flex;
  list-style: none;
  margin-top: 10px;
  gap: 15px;
}

#social-media img {
  width: 30px;
  height: 30px;
}

.footer-container-two {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.footer-container-two ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style: none;
}

.footer-container-two ul li {
  color: white;
  gap: 30px;
  font-size: 14px;
  color: black;
}
.tc {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}

.copyright-mob {
  display: none !important;
  visibility: hidden;
}

#copyright {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

#companyname {
  /* color: #4285f4; */
  color: rgb(36, 49, 103);
  font-weight: 600;
}

#mail {
  color: white;
}

#call {
  color: white;
}

@media only screen and (max-width: 592px) {
  .footer-container-one {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 5px 10px;
  }

  .copyright-pc {
    display: none !important;
    visibility: hidden;
  }

  .footer-contact {
    margin-top: 16px;
  }

  .copyright-mob {
    visibility: visible;
    display: block !important;
  }

  .footer-products {
    width: 35%;
  }

  .footer_company_info {
    width: 65%;
  }

  .footer-box {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    margin: 0 auto;
  }

  #address_info p {
    position: relative;
    left: 2px;
  }

  .footer-products ul {
    font-size: 16px;
  }

  .footer-contact ul {
    font-size: 16px;
  }

  .advertise {
    display: flex;
    padding: 0 8px;
    width: 100%;
  }

  .advertise p {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    margin-top: 10px;
  }

  #aryologo {
    width: 120px;
    position: relative;
    left: -4px;
  }

  #badge {
    margin-top: 10px;
    position: relative;
    left: -8px;
  }

  #companyname {
    color: #fff;
  }

  #year{
    font-weight: 600;
  }
}

.media_link:hover {
  text-decoration: none;
}
