.reviews-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 30px;
}

#reviews-heading {
  font-size: 2rem;
  font-weight: 500;
}

.review-box {
  clear: unset !important;
  width: calc(100% - 20px);
  margin: 15px 20px 20px 0px;
  position: relative !important;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.reviews-container {
  display: flex;
  width: 100%;
  padding: 30px 50px;
}

.rating-wrapper {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

#rating-box {
  display: flex;
  float: left;
  padding-bottom: 20px;
}

#rating-box img {
  width: 20px;
  height: 20px;
  position: relative;
  /* right: 9px; */
}

.slick-slide img {
  display: block;
  padding: 0;
}

.review-data {
  padding: 20px 0;
}

.review-data p {
  font-size: 18px;
}

.user-box {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

#happy_user {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.user_name {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  /* right: 2px; */
}

.reviewers-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}

.reviewers-logo img {
  width: 90%;
}

.review-container {
  width: 50%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .reviews-wrapper {
    padding: 40px 20px;
  }

  #reviews-heading {
    font-size: 24px;
    font-weight: 500;
    position: relative;
  }

  .rating-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .user-box {
    align-items: center;
  }

  .reviews-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 0;
  }

  .review-container {
    width: 100%;
  }

  .review-data {
    padding: 20px 0;
  }

  .review-box {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  .reviewers-logo {
    display: none;
    visibility: hidden;
  }



  #rating-box img {
    width: 20px;
    height: 20px;
    position: relative;
    right: 0;
  }
}
