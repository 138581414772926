.benefits-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 30px;
  /* background-image: linear-gradient(62deg, #f4f4f4 0%, #f8f8f8 100%);  */
  background-color: #fff;
}

.sales-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 50px 0;
}

.sales-logo img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-image: radial-gradient(
    rgb(247, 253, 255),
    rgb(227, 244, 255),
    rgb(132, 222, 238)
  );
}

.benefits-data {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  text-align: left;
  width: 50%;
  padding: 50px 0;
}

#benefits-title {
  font-size: 22px;
  font-weight: 500;
  color: rgb(13, 13, 19);
  margin-bottom: 20px;
}

#info-para {
  line-height: 2.1;
  font-size: 17px;
  font-weight: 400;
  width: 100%;
}

#info-para img {
  width: 18px;
  height: 18px;
  position: relative;
  top: 2.5px;
}

@media only screen and (max-width : 768px){
  .benefits-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    row-gap: 40px;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    padding: 20px 0 80px 0;
  }

  .benefits-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    padding-left: 15px;
  }

  #benefits-title {
    font-size: 17px;
    font-weight: 500;
    color: rgb(13, 13, 19);
    margin-bottom: 20px;
  }
  
  .sales-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sales-logo img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-image: radial-gradient(
      rgb(247, 253, 255),
      rgb(227, 244, 255),
      rgb(132, 222, 238)
    );
    opacity: 1;
  }

  #info-para {
    line-height: 2;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }

  #info-para img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: -4px;
  }
}