    .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    height: 5vh;
    width: 5vh;
    border: 4px dotted;
    border-color: transparent #4285f4 transparent #4285f4 ;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


  