.privacy_container {
  margin-left: 30px;
  margin-right: 30px;
  padding: 30px 5px;
}
p,
li {
  font-size: 16px;
}

#pp {
  margin-top: 20px;
  text-align: center;
}

ol {
  padding-left: 15px;
}

@media only screen and (max-width: 768px) {
  .privacy_container {
    margin-left: 10px;
    margin-right: 10px;
  }
}
