.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease;
  z-index: 5;
}

.header-container{
  padding: 20px 0 0;
}

.contact_header_container{
  padding: 5px 0;
  background-color: #307cf6;
}

/* 2f00ff,#016afb,#03a0ff,#00d2ff,#00ecff */

.company_info,
.navbar-menu {
  padding: 10px 30px;
}

.playstore_logo {
  display: none;
  visibility: hidden;
}

.linkPath-logo {
  display: flex;
  text-decoration: none;
  color: #4285f4;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 2px 2px 2px rgba(218, 241, 241, 0.5);
}




.linkPath-menu {
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.hamburger-link,
#hamburger-menu {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .header-wrapper {
    display: none;
    visibility: hidden;
  }

  .contactpage-Header {
    display: none;
    visibility: hidden;
  }

  .company_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px 0px 0;
    width: 100%;
  }

  .linkPath-menu {
    font-size: 12px;
  }

  /* .hamburger-link, */
#hamburger-menu {
  display: block;
  visibility: visible;
}

  .hamburger-link {
    display: block;
    visibility: visible;
  }

  .navbar-menu {
    display: none;
    visibility: hidden;
  }

  #hamburger-menu {
    display: block;
    visibility: visible;
    align-self: flex-end;
  }
}

