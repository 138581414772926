.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
  width: 100%;
  gap: 50px;
  z-index: 1;
  height: 100vh;
}

.linkPath-badge {
  width: 200px;
  margin-top: 40px;
}


.qrBox{
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  position: fixed;
  right: 50px;
  bottom: 70px;
  z-index: 1000;
}


.qrBox img{
  width: 80px;
}

.qrBox p {
  font-size: 1rem;
  font-weight: 600;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  text-align: center;
  position: relative;
  right: 30px;
}

.link-banner-mobile {
  display: none;
  visibility: hidden;
}

#video {
  width: 200px;
}

#sell {
  font-size: 44px;
  background: -webkit-linear-gradient(rgb(23, 22, 22),rgb(42, 42, 42));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  bottom: 10px;
  z-index: 2;
}


.write-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  z-index: 2;
}

#banner-writeup {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  position: relative;
  top: 20px;
}

#banner-bharat {
  font-size: 28px;
  font-weight: 700;
  color: yellow;
  position: relative;
  bottom: 20px;
}

.rocket{
  position: relative;
  width: 60px;
  top: 8px;
}

#sell2 {
  font-size: 40px;
  color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: #ffffff;
}

#write-up_suffix {
  font-size: 40px;
  font-weight: 600;
}

#andlogo {
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
} 

#write-up_suffix{
  color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: #ffffff;
}



#write-up_suffix span {
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  -webkit-background-clip: text;
}

#banner-badge {
  width: 200px;
  /* margin-top: 40px; */
}

.star-rating {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 10px;
}

.star {
  display: flex;
  position: relative;
  left: 10px;
}

.review_count {
  color: gray;
  position: relative;
  top: 2px;
  left: 2px;
}

.star-rating img {
  width: 20px;
}

#rating {
  position: relative;
  left: 7px;
}

.star_rating_mobile {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .banner-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    /* margin-top: 25px; */
  }

  .qrBox{
    display: none;
    visibility: hidden;
  }

  .banner {
    width: 100%;
    height: auto;
    right: 0;
    bottom: 15px;
    flex-direction: column;
  }

  #video {
    width: 180px;
  }
  

  .link-banner-mobile {
    display: block;
    visibility: visible;
  }

  .write-up {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  #banner-bharat {
    font-size: 22px;
    font-weight: 700;
    color: #d4d4d4;
    position: relative;
    bottom: 5px;
   
  }

  #banner-writeup {
    font-size: 1px;
    top: 10px;
  }

  #sell {
    background: -webkit-linear-gradient( #c5c037, #c6c12f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgb(235, 235, 36);
  font-size: 28px;
  }

  #sell2 {
    font-size: 24px;
  }

  #write-up_suffix {
    font-size: 24px;
  }

  .linkPath-badge {
    display: none;
    visibility: hidden;
  }

  #banner-badge {
    width: 200px;
    margin-top: 0;
  }

  .star-rating {
    display: none;
    visibility: hidden;
  }

  .star_rating_mobile {
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 25px;
    margin: 0 auto;
    position: relative;
    bottom: 5px;
  }

  .star_rating_mobile img {
    width: 20px;
  }

  .rocket{
    position: relative;
    width: 40px;
    top: 8px;
  }
  

  .star_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .review_count {
    color: gray;
    position: relative;
    top: 12px;
  }

  #rating_ss {
    color: gray;
    position: relative;
    top: 1px;
    left: 5px;
  }
}


