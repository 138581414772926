.product-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 30px;
  width: 100%;
  /* background-color: #e5effe;
  background: linear-gradient(
    to bottom,
    rgb(199, 225, 255),
    rgb(200, 219, 248),
    rgb(248, 250, 255),
    rgb(248, 254, 255),
    rgb(212, 226, 255)
  ); */
  background-color: #fff;
}

#product-heading {
  font-size: 2rem;
  font-weight: 500;
}

.product-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  column-gap: 70px;
  row-gap: 40px;
  padding-top: 50px;
}

.product {
  display: flex;
  border-radius: 14px;
  padding: 30px 20px;
  gap: 10px;
  width: 380px;
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 120px;
}

.product-image img {
  max-width: 100%;
}

.bnpl{
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: center;
}

.product-container .product:nth-child(1) {
  background-color: rgb(235, 236, 255);
  /* box-shadow: 0 5px 10px rgb(190, 193, 253) inset, 0 3px 2px rgb(190, 193, 253); */
}
.product-container .product:nth-child(2) {
  background-color: rgb(226, 255, 220);
  /* box-shadow: 0 5px 5px rgb(171, 237, 157) inset, 0 3px 6px rgb(171, 237, 157); */
}

.product-container .product:nth-child(3) {
  background-color: rgb(246, 252, 218);
  /* box-shadow: 0 5px 10px rgb(237, 249, 187) inset, 0 3px 6px rgb(237, 249, 187); */
}

.product-container .product:nth-child(4) {
  background-color: rgb(213, 213, 213);
  /* box-shadow: 0 5px 10px rgb(179, 194, 203) inset, 0 3px 6px rgb(179, 194, 203); */
}

.product-container .product:nth-child(5) {
  background-color: rgb(255, 221, 220);
  /* box-shadow: 0 5px 10px rgb(255, 207, 205) inset, 0 3px 6px rgb(255, 207, 205); */
}

.product-container .product:nth-child(6) {
  background-color: rgb(255, 244, 224);
  /* box-shadow: 0 5px 10px rgb(255, 237, 204) inset, 0 3px 6px rgb(255, 237, 204); */
}

.product-heading {
  font-size: 17px;
  font-weight: 700;
  font-family: "Gilroy-Medium", sans-serif;
}

.product-detail {
  line-height: 1.4;
  font-size: 14px;
  margin-top: 10px;
  list-style: none;
  font-family: "Gilroy-Medium", sans-serif;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 120px;
}

#laon_logo {
  max-width: 50%;
}

.product-detail {
  list-style: none;
  margin-top: 10px;
}

.product-name {
  font-size: 19px;
  font-weight: 600;
  color: gray;
  text-shadow: 2px 2px 2px rgba(106, 109, 111, 0.5);
}

@media only screen and (max-width: 768px) {
  .product-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 40px;
    background: linear-gradient(
      to bottom,
      rgb(239, 250, 254),
      rgb(239, 249, 255),
      rgb(248, 254, 255),
      rgb(248, 254, 255),
      rgb(248, 254, 255)
    );
  }

  .product-container {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 40px;
    padding-top: 30px;
  }

  .product {
    display: flex;
    gap: 0;
    justify-content: space-between;
    border-radius: 14px;
    padding: 25px 12px;
    width: 330px;
  }

  #product-heading {
    font-size: 24px;
    font-weight: 500;
  }

  .bnpl{
    grid-column-start: 1;
    grid-column-end: 1;
    justify-self: center;
  }
}

@media only screen and (max-width: 356px) {
  .product {
    display: flex;
    gap: 0;
    justify-content: space-between;
    border-radius: 14px;
    padding: 25px 12px;
    width: 300px;
  }
}
