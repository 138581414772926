.appinfo-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: #212020; */
  /* background-color: black; */
  background-color: #4285f4;
  width: 100%;
  padding: 30px 50px;
}



.appinfo-box p {
  line-height: 1.5;
}

.over {
  font-size: 12px;
  color: rgb(59, 57, 57);
  font-weight: 600;
}

.count {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.14;
  color:  rgb(253, 211, 53);
}

.specific-info {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.52;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .appinfo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0;
  }

  .appinfo-box {
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
  }

  .appinfo-box p {
    line-height: 1.5;
  }

 

  .count {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.14;
  }

  .specific-info {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.52;
  }
}
