.account_header{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 90%;
  background-color: #ffffff;
  border-bottom: 2px solid rgb(194, 193, 193 , 0.5);
  margin: 0 auto;
  font-family: 'Times New Roman', Times, serif;
}

.acc_heading{
  padding: 10px 0 ;
  text-align: left;
  width: 100%;
}

.acc_heading p{
  font-size: 24px;

}

.account_wrapper{
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 90%;
  font-family: 'Times New Roman', Times, serif;
  margin: 0 auto;
}

.profile_details{
  align-self: start;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
}

.contact_details{
  line-height: 1.7;
  padding: 0 10px;
}

.contact_details p:nth-child(1){
  font-size: 16px;
  font-weight: 600;
}

.contact_details p:nth-child(5){
  font-size: 16px;
  font-weight: 500;
  color: #4285f4;
  cursor: pointer;
}


.other_details{
  display: flex;
  flex-direction: column;
width: 100%;
}

.update-container{
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 30px 0;
 width: 100%;
 border-bottom: 2px solid rgb(194, 193, 193 , 0.5);
 margin-top: 5px;
 cursor: pointer;
 text-decoration: none;
}

.update-details{
  display: flex;
  line-height: 1.7;
  padding: 0 10px;
}

.update-details img{
  width: 40px;
  height: 40px;
 
}

.update-details p{
  margin-left: 10px;
}



.update-details p:nth-child(1){
  font-size: 16px;
  font-weight: 600;
}

.update-details p:nth-child(2){
  font-size: 15px;
  font-weight: 500;
  color: gray;
}

.update-container_button button{
padding: 12px;
border-radius: 47%;
border-style: none;
background-color: #4285f4;
color: #fff;
font-size: 14px;
cursor: pointer;
}



/* <div className="profile_details">
<img src={user} alt="" />
<div className="contact_details">
  <p>{props.basicDetails.name}</p>
  <p>{props.basicDetails.mobile}</p>
  <p>{props.basicDetails.email}</p>
  <p>{props.basicDetails.pincode}</p>
  <p>Edit Profile</p>
</div>
</div> */