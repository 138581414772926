.kyc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 50px;
  background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%);
}

.kyc-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 40px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}



.kyc-banner{
  width: 50%;
  text-align: right;
}

.kyc-banner img {
  display: block;
  /* margin: auto; */
  max-width: 80%;
}

.kyc-banner-text{
  text-align: left;
  line-height: 1.4;
}

.kyc-banner-text p:nth-child(1){
  font-size: 28px;
  font-weight: 600;
}
.kyc-banner-text p:nth-child(2){
  font-size: 15px;
  font-weight: 500;
  color: gray;
}

.kyc-screen {
  padding: 0 80px;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  /* margin: 0 auto; */
}


.item-checkbox{
  display: flex;
  margin-top: 15px;
}



/* .kyc_heading {
  width: 100%;
  text-align: left;
}

.kyc_heading p {
  font-size: 23px;
  color: black;
} */

.detail_input_label {
  width: 100%;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 4px;
}

.detail_input_label p {
  margin-bottom: 0;
}

.detail_input {
  margin-top: 0;
  width: 100%;
}

.detail_input input {
  padding: 15px 10px;
  width: 100%;
  border-style: none;
  background-color: #fff;
  border-radius: 6px;
  text-transform: uppercase;
}

.detail_input input:disabled {
  background-color: rgb(223, 223, 223);
}

.detail_input input:focus {
  outline-style: none;
}

.kyc_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4285f4;
  padding: 8px 0;
  margin-top: 20px;
}

.kyc_button.disabled{
  background-color:  #bebebe;
  cursor: default;
}

.kyc_button button {
  width: 80%;
  background: transparent;
  border-style: none;
  font-size: 14px;
  padding: 10px 0;
  color: #fff;
  font-weight: 600;
  align-self: center;
  /* font-family: "Poppins", sans-serif; */
  letter-spacing: 2px;
}

#kbutton {
  width: 20px;
  height: 20px;
}

.uploadDoc-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 90px;

}

.uploadDoc {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 50px;
}


.uploadDoc button{
  padding: 6px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 6px;
  width: 100px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.uploadDoc button:active{
  background-color: gray;
}

.uploadDoc button:disabled{
  background-color: gray;
  cursor: default;
}

.document-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}


.documentt_submit-button button{
  width: 100%;
  border-style: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: #4285f4;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
}

@media (max-width: 750px) {

  .kyc-wrapper {
    padding: 10px 8px;
  }

  .kyc-box {
    width: 100%;
  }

  .kyc-screen {
    padding: 16px;
  }

}


