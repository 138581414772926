.login-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 50px 30px 140px;
 
}

.login-banner {
  width: 50%;
  text-align: right;
}

.login-banner img {
  display: block;
  max-width: 100%;
}

.login-box {
  width: 45%;
  padding: 120px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}

.banner-text {
  text-align: left;
  line-height: 1.4;
}

.banner-text p:nth-child(1) {
  font-size: 26px;
  font-weight: 600;
}

.banner-text p:nth-child(2) {
  font-size: 26px;
  font-weight: 600;
  color: #4285f4;
}

.banner-text p:nth-child(3) {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.otp_screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.login-heading {
  width: 100%;
  text-align: left;
}

.login_page_heading {
  font-size: 23px;
  color: black;
}

.mobile_input_label {
  width: 100%;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 4px;
}
.mobile_input_label p {
  margin-bottom: 0;
}

.mobile_input {
  margin-top: 0;
  width: 100%;
}

.mobile_input input {
  padding: 15px 10px;
  width: 100%;
  border-style: none;
  background-color: #fff;
  border-radius: 6px;
}

.mobile_input input:focus {
  outline-style: none;
}

.mobile-error{
  margin-top: 5px;
  text-align: left;
  width: 100%;
}
.mobile-error p {
  color: red;
  margin-bottom: 0;
  font-size: 12px;
}

.otp_button {
  width: 100%;
  display: flex;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4285f4;
  padding: 15px 10px;
  margin-top: 20px;
}

.otp_button button {
  width: 80%;
  background: transparent;
  border-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  align-self: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

#fbutton {
  width: 20px;
  position: relative;
  left: 20px;
}

.termsandconditions {
  margin-top: 30px;
  text-align: left;
  width: 100%;
}

.terms-policy {
  color: #4285f4;
}

@media (max-width: 750px) {
 
  .login-wrapper {
    flex-direction: column;
    padding: 80px 10px 20px;
    height: 100vh;
    background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%);
  }
  
  .login-banner {
    display: none;
  }

  /* img {
    width: 0;
  }
   */
  /* .banner-text p:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
  } */
  
  /* .banner-text p:nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    color: #4285f4;
  }
   */
  /* .banner-text p:nth-child(3) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  } */

  .login-box {
    width: 100%;
    margin-top: 16px;
    background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
    border-radius: 8px;
    box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
    padding: 120px 0;
  }
  




}
