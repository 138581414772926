.otp-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 50px 30px 140px;
  /* border: 2px solid grey; */
  /* background-image: linear-gradient(160deg, #31B7C2 0%, #7BC393 100%); */
  /* background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%); */
}

.otp-banner{
  width: 35%;
  text-align: right;
}

.otp-banner img {
  display: block;
  margin: auto;
  max-width: 100%;
}


.otp-box {
  width: 45%;
  padding: 120px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}


.otp_heading{
  width: 100%;
  text-align: left;
}

.otp_page_heading{
  font-size: 23px;
  color: black;
}


.otp_message{
  width: 100%;
  text-align: left;
  margin-top: 20px;
}

.otp_message p{
  color: gray;
}

.otp_input_label{
  width: 100%;
  margin-top: 30px;
  text-align: left;
  margin-bottom: 4px;
}

.otp_input_label p {
  margin-bottom: 0;
}

.otp_input{
  margin-top: 0;
  width: 100%;
  text-align: left;
}

.otp_input input {
  padding: 15px 10px;
  width: 100%;
  border-style: none;
  background-color: #fff;
  border-radius: 6px;
}

.otp_input span{
  font-size: 12px;
  color: red;
  margin-top: 2px;
}

.verify_otp_button{
  width: 100%;
  display: flex;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4285f4;
  padding: 15px 10px;
  margin-top: 20px;
}

.verify_otp_button.disabled{
  background-color:  #bebebe;
  cursor: default;
}

.verify_otp_button button{
  width: 100%;
  background: transparent;
  border-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  align-self: center;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

.verify_otp_button button:disabled{
  cursor: default;
}

.content {
  margin-top: 5px;
}

@media (max-width: 750px) {

  .otp-wrapper {
    flex-direction: column;
    padding: 80px 10px 20px;
    height: 100vh;
    background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%);

  }
  .otp-box {
    width: 100%;
    margin-top: 16px;
    background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
    border-radius: 8px;
    box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
    padding: 120px 0;
  }


  
 .otp-banner {
    display: none;
    visibility: hidden;
  }
}
