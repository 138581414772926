.dropbtn {

    display: flex;
    width: 100%;
    background: white;
    border-width: 0px;
    cursor: pointer;
    justify-content: space-between;
}


.dropdown {
    width: 100%;
    background-color: white;
    border-style: none;
    border-radius: 6px;
    text-align: left;
    padding: 15px 10px;
  }

.drpitm {
    font-size: 13px;
    color: #4285f4;
    margin-top: 16px;
    border-bottom-width: 1px;
    border-bottom-color: gray;
    cursor: pointer;
}
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /* background-color: rgb(208, 205, 205); */
    z-index: 1;
    padding: 6px 0;
    width: 100px;
    text-align: center;
    margin-top: 10px;
    border-radius: 6px;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

.fileSelector {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    background: white;
    border-radius: 5px;
    width: 100%;
    flex-wrap: wrap;
  }

  .fileSelector  input{
    width: 70%;
  }
    

  .fileSelector button{
    background-color: #4285f4;
    padding: 6px;
    border-style: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
} 

.fileSelector button:disabled {
  background-color:  #bebebe;
  cursor: default;
}
  

  .center-screen {
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh; */
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background-color: rgba(169, 169, 169, 0.5);
    font-family: 'Open Sans', sans-serif;
  }

  .inactive_div {
    background-color: gray;
  }
 