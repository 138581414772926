.steps-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background: linear-gradient(168deg, rgb(233, 244, 255) 100%, rgb(255, 255, 255) 0%);
  width: 100vw;
}

.steps-box {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 30px 0 5px;
}

#subheading {
  color: gray;
  position: relative;
  top: 4px;
}

.stepnumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  top: 10px;
}

.stepnumber p {
  color: #4285f4;
  font-size: 20px;
  margin: 0 auto ;
}

.stepinfo p {
  color: black;
  font-size: 20px;
}

.steps-box img {
  width: 220px;
}
