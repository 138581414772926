.company_details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 50px 30px 140px;
  background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%);
}

.company-banner {
  width: 50%;
  text-align: right;
}

.company-banner img {
  display: block;
  max-width: 80%;
}

.company-banner-text {
  text-align: left;
  line-height: 1.4;
}

.company-banner-text p:nth-child(1) {
  font-size: 22px;
  font-weight: 600;
}

.company-banner-text p:nth-child(2) {
  font-size: 22px;
  font-weight: 600;
  color: #4285f4;
}

.company-banner-text p:nth-child(3) {
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
}

.profile_box {
  width: 60%;
  padding: 60px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
  margin: 0 auto;
}

.details_box {
  width: 45%;
  padding: 60px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}

.comapny-details-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.details_heading {
  width: 100%;
  text-align: left;
}

.details_heading p {
  font-size: 20px;
  color: black;
  font-weight: 500;
}

.bank_detail_button {
  width: 100%;
  display: flex;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4285f4;
  padding: 15px 10px;
  margin-top: 30px;
}

.bank_detail_button button {
  width: 80%;
  background: transparent;
  border-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  align-self: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

@media (max-width: 750px) {
  .company_details-wrapper {
    padding: 10px 5px;
  }
}

@media only screen and (max-width: 768px) {
  .profile_box {
    width: 98%;
  }

  .comapny-details-form {
    width: 90%;
  }
}
