.bank-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 50px 30px 100px;
  background-image: linear-gradient(160deg, #c5dbff 0%, #e3eeff 100%);
}

.bank-banner{
  width: 50%;
  text-align: right;
}

.bank-banner img{
  display: block;
  max-width: 80%;
}

.bank-banner-text{
  text-align: left;
  line-height: 1.4;
}

.bank-banner-text p:nth-child(1) {
  font-size: 22px;
  font-weight: 600;
}

.bank-banner-text p:nth-child(2) {
  font-size: 22px;
  font-weight: 600;
  color: #4285f4;
}

.bank-banner-text p:nth-child(3) {
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
}

.bank_details-box{
  width: 60%;
  padding: 60px 0;
  background-image: linear-gradient(160deg, #dceffe 0%, #e9e8fe 100%);
  border-radius: 8px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
  margin: 0 auto;
}

.bank_details-screen{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 0 30px;
  margin: 0 auto;
}


.bank_button{
  width: 100%;
  display: flex;
  border-style: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4285f4;
  padding: 15px 10px;
  margin-top: 30px;
}


.bank_button button {
  width: 80%;
  background: transparent;
  border-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  align-self: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

@media (max-width: 750px) {
  .bank-wrapper{
    justify-content: center;
    padding: 16px 5px;
    height: 100vh;
  }
}

@media only screen and (max-width: 768px) {
  
  .bank_details-box {
    width: 98%;
  }

  .bank_details-screen {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
  }
}