.partner-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 30px;
}

#partner-heading {
  font-size: 2rem;
}

#p1 {
  font-weight: 700;
  color: #4285f4;
}

.partner-logos-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 10px;
  column-gap: 30px;
  width: 100%;
  margin-top: 40px;
}

.partner-logos {
  justify-self: center;
  display: flex;
  align-items: center;
}

.partner-logos img {
  width: 80;
  height: 80px;
}

/* .partner-logos-container-mobile {
  display: none;
  visibility: hidden;
} */

#idfc {
  width: 140px;
  height: 40px;
}

#au {
  width: 140px;
  height: 50px;
}

@media only screen and (max-width: 768px) {
  .partner-wrapper {
    padding: 10px 0 0;
    /* background-color: aqua; */
  }

  #partner-heading {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    padding: 10px 0;
  }

  .partner-logos-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 30px;
    column-gap: 3px;
    width: 100%;
    margin-top: 0;
    padding: 10px 0;
    /* border-top:2px solid rgb(194, 193, 193 , 0.5); */
  }

  /* .partner-logos-container-mobile {
    display: block;
    visibility: visible;
    padding:20px 0 40px;
  }

  .partner-logos-mobile {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 80px;
  } */

  .partner-logos{
    width: 100%;
    padding: 10px 0;
  }

  .partner-logos img {
    display: block;
    margin: 0 auto;
    width: 120px;
  }

  /* #au_mobile{
    width: 160px;
    height: 40px;
  }

  #idfc_mobile{
    width: 160px;
    height: 40px;
  } */
}
