@font-face {
  font-family: "Gilroy-Medium";
  src: url("../Fonts/Gilroy-Medium.ttf") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Medium", sans-serif;
}




