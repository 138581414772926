*,
*::before,
*::after {
  box-sizing: border-box;
}

.img-slider-img {
  object-fit: cover;
  width: 200px;
  height: 400px;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 auto;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow-y: hidden;
}

.slider-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* transition: transform 1s ease-in; */
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
  25% {
		opacity: 0.35;
	}
  50% {
		opacity: 0.6;
	}
  75% {
		opacity: 0.8;
	}
	100% {
		opacity: 1;
	}
}

