.delete-wrapper{
  padding: 20px 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.delete-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 550px;
  border: 0.5px solid gray;
  border-radius: 6px;
  padding: 20px;
  background-color: #c8d6eb;
  width: 350px;

}

.delete_input{
  width: 100%;
}

.delete_input label{
  font-size: 15px;
  color: #555;
  position: relative;
  bottom: 2px;
}

.delete_input input{
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border-style: none;
  font-size: 16px;
  letter-spacing: 1px;
  color: #555;
  outline: none;
}

.delete_input input{
  position: relative;
  bottom: -2px;
}

.delete_input input:focus {
  border: 0.1px solid #86a8df; 
}
.delete_button{
  width: 100%;
}

.delete_button button{
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border-style: none;
  background-color: #4285f4;
  color: #fff;
  font-size: 16px;
}

.delete-error{
  margin-top: 5px;
  text-align: left;
  width: 100%;
  color: red;
  margin-bottom: 0;
  font-size: 12px;
}

