.progress-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(168deg, rgb(233, 244, 255) 100%, rgb(255, 255, 255) 0%);
  padding: 50px 30px;
}

.steps-wrapper{
  display: none;
  visibility: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 50px 10px;
  width: 70%;
}

.container .steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px 0;
}

.steps .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color:  rgb(93, 92, 92);
  font-size: 18px;
  font-weight: 500;
  border-radius: 50%;
  background: #fff;
  border: 3px solid  rgb(93, 92, 92);
  transition: all 100ms ease; 
  transition-delay: 0s;
  z-index: 2;
}

.step_img {
  width: 20px;

}

.steps .circle.active {
  transition-delay: 100ms;
  border-color:#4285f4;
  color: #4285f4;
  z-index: 4;
}

.steps .progress-bar {
  position: absolute;
  width: 2px;
  height: 90%;
  z-index: 1;
  overflow: hidden;
  
}

.steps  .indicator {
  position: absolute;
  width: 100%;
  height: 90%;
  background: #4285f4;
  transition: all 90ms ease;
  overflow: hidden;
  z-index: 1;
}


.steps .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  color: rgb(93, 92, 92);
  font-size: 19px;
  font-weight: 500;
  transition: all 90ms ease;
  transition-delay: 0s;
  /* padding: 2.5px 0 0; */
  border: none;
}

.steps .info.active {
  transition-delay: 90ms;
  color: #4285f4;
}

.ss-slider {
  display: flex;
  align-items: center;
  width: 25%;
  margin-left: auto;
}

.doc_img{
  width: 100px;
}

 .process{
width: 20px;
}

.share {
  width: 20px;
}
.step-container{
  display: flex;
  gap: 20px;
}

.steps_details{
  padding: 22px 0 0!important;
}

.secondstep{
  position: relative;
  top: 8px;
}

.thirdstep{
  position: relative;
  top: 7px;
}

.fourthstep{
  position: relative;
  bottom: 9px;
}

@media only screen and (max-width : 768px){
  .progress-wrapper{
    display: none;
    visibility: hidden;
  }

  .steps-wrapper{
    display: flex;
    visibility: visible;
  }
  

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    gap: 30px;
  }

  .container .steps {
    padding: 0;
    gap: 35px;
    padding: 0;
  }


  .steps .info {
    width: 300px;
    color: #a09f9f;
    font-size: 17px;
    padding: 4px 0 4px;
  }

  .steps .progress-bar {
    position: absolute;
    width: 2px;
    height: 80%;
    z-index: 1;
    overflow: hidden;
    
  }

  
  
  .steps  .indicator {
    position: absolute;
    width: 100%;
    height: 80%;
    background: #4285f4;
    transition: all 90ms ease;
    overflow: hidden;
    z-index: 1;
  }
  

  .ss-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: auto;
  }

  .container .steps {
    padding: 20px 0 !important;
  }


}

