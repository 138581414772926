.blogs-wrapper {
  padding: 4rem 2rem;
}

.blogs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  margin: auto;
  gap: 1rem;
}


.first-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: center;
  text-decoration: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px,rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.first-box img {
  max-width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.description-box {
  width: 100%;
  padding: 1.3rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}



.description-box-heading {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.description-box-heading p {
  font-size: 19px;
  color: #555;
  font-weight: 600;
}

.description-box-heading h3 {
  color: #555;
  font-weight: 400;
  font-size: 17px;
}

.description-box-date p {
  font-size: 11px;
  color: #555;
 
}


.description {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.description-headng {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.description-headng p {
  font-size: 12px;
  color: rgb(190, 190, 190);
  font-weight: 600;
}

.description-headng h3 {
  color: #555;
}

.description-date p {
  font-size: 11px;
  color: #7c7c7c;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .blogs-wrapper {
    padding: 2rem 0.5rem;
    margin-top: 48px;
  }

  .blogs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
  }

  .first-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  .first-box img {
    max-width: 100%;
  }

  .description-box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
