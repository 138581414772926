/* Navbar CSS */
.banner-screen {
  background: url("../images/curve2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: -50%;
  transition: all 0.7s ease;
  z-index: 5;
}

.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge_box {
  display: none;
  visibility: hidden;
}

/* 2f00ff,#016afb,#03a0ff,#00d2ff,#00ecff */

.company_info,
.navbar-menu {
  padding: 10px 30px;
}

.playstore_logo {
  display: none;
  visibility: hidden;
}

.logo-container {
  display: flex;
  padding: 10px 0;
}

.linkPath-logo,
.linkPath-logo_navbar {
  display: flex;
  text-decoration: none;
  color: #4285f4;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 2px 2px 2px rgba(218, 241, 241, 0.5);
}

.linkPath-logo img,
.linkPath-logo_navbar img {
  width: 100px;
}

.menu-1 {
  list-style: none;
  display: flex;
  gap: 6px;
}

.menu-1 li {
  margin-right: 20px;
}

.linkPath-menu_navbar {
  cursor: pointer;
  font-size: 16px;
  color: #4285f4;
  font-weight: 600;
  text-decoration: none;
}

.hamburger-link,
#hamburger-menu_navbar {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  .linkPath-menu_navbar {
    cursor: pointer;
    font-size: 15px !important;
    color: black;
    font-weight: 500;

  }

  .navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    transition: all 0.7s ease;
    padding: 8px 10px 8px;
    height: 50px;

  }

  .linkPath-logo_navbar img {
    width: 80px;
  }

  .badge_box {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    position: relative;
    right: 0;
  }

  #navbar_badge {
    width: 90px;
  }

  #navbar_badge_aryo{
    width: 90px;
    position: relative;
    top: 5px;
  }

  .company_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 0;
    width: 100%;
  }

  .linkPath-menu_navbar {
    font-size: 12px;
  }

  .hamburger-link {
    display: block;
    visibility: visible;
  }

  .navbar-menu {
    display: none;
    visibility: hidden;
  }

  #hamburger-menu_navbar {
    display: block;
    visibility: visible;
    align-self: flex-end;
    position: relative;
    z-index: 10;
  }

  .hamburger-link {
    height: 100%;
    width: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.1s;
  }

  #myNav {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
  }

  .hamburger-content {
    padding: 0 5px;
    width: 100%;
    text-align: left;
    position: relative;
    z-index: 5;
  }

  .hamburger-link li {
    padding: 8px;
    font-size: 24px;
    font-weight: 500;
    transition: 0.3s;
  }

  /* .hamburger-link li:hover,
  .overlay li:focus {
    color: #ffffff;
  } */

  .hamburger-link .closebtn {
    position: absolute;
    padding: 10px 5px;
    right: 0;
    font-size: 20px;
    font-weight: 600;
    color: #4285f4;
  }

  #hamburger-menu_navbar {
    display: inline-block;
    cursor: pointer;
    padding: 0 12px;
  }

  .badge_box {
    /* Your default styling for the badge_box */
    display: none; /* Initially hide the badge */
  }

  .badge_box.visible {
    display: flex; /* Show the badge when the visible class is present */
  }

  #hamburger-menu_navbar p {
    width: 25px;
    height: 2px;
    background-color: #4285f4;
    margin-top: 4px;
    transition: 0.1s;
    border-radius: 2px;
    position: relative;
    z-index: 5;
  }

  #hamburger-menu_navbar.active p:nth-child(1) {
    transform: rotate(-45deg) translate(-4px, 3px);
    width: 20px;
  }

  #hamburger-menu_navbar.active p:nth-child(2) {
    opacity: 0;
  }

  #hamburger-menu_navbar.active p:nth-child(3) {
    transform: rotate(45deg) translate(-6px, -4px);
    width: 20px;
  }

  .linkPath-menu_navbar.active-link {
    color: #4285f4;
  }

  #logo_navbar {
    border-bottom: 1px solid #4285f4;
  }
}

@media screen and (max-height: 450px) {
  .hamburger-link a {
    font-size: 20px;
  }
  .hamburger-link .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.profile_logo {
  padding: 5px 40px;
}

#name-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-decoration: none;
}

#name-logo p {
  color: #4285f4;
  font-size: 24px;
  letter-spacing: 1px;
}
