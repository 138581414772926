.terms_container {
  margin-left: 30px;
  margin-right: 30px;
  padding: 30px 5px;
}

.img-fluid {
  width: 150px;
}

#tnc {
  margin-top: 10px;
  text-align: center;
}

#logo {
  margin-left: 0px;
}

@media only screen and (max-width: 768px) {
  .terms_container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .img-fluid {
    width: 150px;
  }

  p,
  li {
    font-size: 15px;
  }
  h1 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.1rem;
  }
}
