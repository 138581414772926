.contact-wrapper {
  display: block;
}

.light-mode{
  background-color: green;
}

#company-name {
  font-size: 26px;
  color: black;
  font-weight: 700;
}

.contact-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px 20px 50px;
  overflow: hidden;
}

.dsa-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 50%;
  padding: 20px 50px;
  gap: 5px;
}

.highlighted-heading {
  font-weight: 700;
  font-size: 2.4rem;
  color: #4285f4;
}

.normal-heading {
  font-weight: 700;
  font-size: 2.4rem;
}

#our-message {
  color: gray;
  margin-top: 10px;
}

#aryopartner {
  width: 500px;
  position: relative;
  top: 35px;
  /* left: 35px; */
  /* align-self: center; */
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 20px 50px;
  gap: 30px;
}

.input-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.input-Box label {
  color: black;
  font-size: 15px;
  font-weight: 500;
  align-self: flex-start;
}

.input-Box label {
  transition: color 0.3s ease; 
}

.input-Box input:focus ~ label,
.input-Box textarea:focus ~ label {
  color: #007bff; 
}

#fname + label,
#email + label,
#phone + label,
#pincode + label,
#message:focus + label {
  color: #007bff !important; /* Set the color you want when the textarea is in focus */
}


input::placeholder {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}

#fname,
#email,
#phone,
#pincode {
  padding: 13px 8px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  width: 100%;
  color: black;
  border: none;
  border-bottom: 0.5px solid rgb(128, 128, 128, 0.5);
  background-color: #fff;
  /* border-radius: 8px; */
}

#fname:focus,
#email:focus,
#phone:focus {
  border-bottom: 0.5px solid rgba(18, 81, 240, 0.5);
  box-shadow: 0.3px 0.3px 0.3px rgb(54, 64, 245);
}

#message:focus {
  border: 0.5px solid rgba(18, 81, 240, 0.5);
  box-shadow: 0.3px 0.3px 0.3px rgb(54, 64, 245);
}

.input-Box input:focus {
  outline-style: none;
}

.error-message {
  display: flex;
  color: red;
  font-size: 12px;
  margin-top: 0.2rem;
  align-self: flex-start;
}

.error-star {
  font-size: 10px;
}

.input-Box-textArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-Box-textArea label {
  color: black;
  font-size: 15px;
  font-weight: 500;
  align-self: flex-start;
  margin-bottom: 12px;
}

#message {
  font: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border: 0.5px solid rgb(128, 128, 128, 0.5);
  padding: 9px 8px;
  border-radius: 8px;
  width: 100%;
  height: 144px;
  background-color: #fff;
}

.input-Box-textArea textarea:focus {
  outline-style: none;
}

#submit-button {
  padding: 10px 30px;
  border-radius: 8px;
  border: none;
  font-size: 17px;
  font-weight: 500;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
}

#submit-button:disabled{
  background-color: gray;
  cursor: none;

}

@media only screen and (max-width: 768px) {
  .contact-wrapper {
    display: block;
    margin-top: 35px;
  }

  .contact-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
    gap: 60px;
  }

  .dsa-info {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    text-align: left;
    width: 100%;
    padding: 0;
  }

  #aryopartner {
    width: 350px;
    /* align-self: center; */
    left: 0;
  }

  .highlighted-heading {
    font-weight: 700;
    font-size: 24px;
    color: #4285f4;
  }

  .normal-heading {
    font-weight: 700;
    font-size: 24px;
  }

  #our-message {
    color: gray;
    margin-top: 0;
    position: relative;
    bottom: 10px;
  }

  .contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5px 0;
  }

  .input-Box label {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    align-self: flex-start;
  }

  .input-Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .input-Box-textArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0;
  }

  #submit-button {
   position: relative;
   bottom: 20px;
  }
}

/* Footer CSS */

.contactUs-address {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #4285f4;
}

.addresss-box {
  width: 40%;
}

.exact-address {
  align-self: flex-start;
  font-family: "Nunito Sans", sans-serif;
  color: #fff;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
}

#live-location-logo {
  width: 20px;
  height: 20px;
}

.contact-info {
  align-self: flex-start;
  font-family: "Nunito Sans", sans-serif;
  color: #fff;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
}



.exact-address-location {
  margin-left: 28px;
}

.pincode-spinner {
  position: absolute;
  top: 70%; /* Adjust this value based on your design preference */
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  height: 5px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding-bottom: 12px;
  padding-right: 16px;
}

#pincode {
  padding-right: 40px; /* Adjust this value to leave space for the loader */
}

#pincode:focus {
  border-bottom: 0.5px solid rgba(18, 81, 240, 0.5);
  box-shadow: 0.3px 0.3px 0.3px rgb(54, 64, 245);
}
