.offers-wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 50px 30px; 
    background: linear-gradient(168deg, rgb(233, 244, 255) 100%, rgb(255, 255, 255) 0%);
}

#offers-heading{
  font-size: 2rem;
  font-weight: 500;
  position: relative;
}

.blue{
  color: #4285f4;
}

/* #offers-heading::after {
  content: '';
  display: block;
  width: 80px; 
  height: 3px; 
  background-color: #4285f4; 
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
} */

.offers-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 60px;
  column-gap: 70px;
  width: 100%;
  padding: 20px 0 20px;
  text-align: center;
}

.offers-logo{
  justify-self: center;
}

.offers-container img{
  width: 150px;
  height: 150px;
}

.offers-name{
  font-size: 18px;
  font-weight: 500;
  color: black;
}

@media only screen and (max-width : 768px) {
  .offers-wrapper{
    padding: 30px 0 20px;
}

  #offers-heading{
    font-size: 24px;
    font-weight: 500;
    position: relative;
  }

  .offers-container{
    grid-template-columns: repeat(1, 1fr);
    padding: 50px 30px 40px;
    row-gap: 40px;
  }

  .offers-container img{
    width: 84px;
    height: 84px;
    /* background: url(../images/wave.jpg);
    background-size: cover;
    background-repeat: no-repeat; */
  }

  .offers-name{
    font-size: 18px;
  }
}